<template>
  <div>
    <div class="botpage">
      <div style="width: 20%;">

      </div>
      <div class="botpage1">
        <div class="botpage2">
          <div>
            <img src="../image/ic.png" class="img">
          </div>
          <div class="tit">
            众聚生活
          </div>
        </div>
        <div class="labelcenter">
          <!-- <div class="labelcenter1">
            <div class="labeltit">
              了解更多
            </div>
            <div class="label1">
              <el-link>新闻中心</el-link>
            </div>
            <div class="label2">
              <el-link>媒体资源</el-link>
            </div>
            <div class="label2">
              <el-link>供应商</el-link>
            </div>
          </div> -->
          <div style="width: 20%;">
            <div class="label3">
              加入我们
            </div>
            <div class="label1">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>社会招聘</el-link>
                </div>
                <div>
                  <img src="../image/wx.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>校园招聘</el-link>
                </div>
                <div>
                  <img src="../image/wx.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>工作兼职</el-link>
                </div>
                <div>
                  <img src="../image/wx.png" class="imgqr">
                </div>
              </el-popover>
            </div>
          </div>
          <div style="width: 20%;">
            <div class="label3">
              联系我们
            </div>
            <div class="label1">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>服务热线</el-link>
                </div>
                <div>
                  <img src="../image/wx.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>廉政举报</el-link>
                </div>
                <div>
                  <img src="../image/wx.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>知识产权</el-link>
                </div>
                <div>
                  <img src="../image/wx.png" class="imgqr">
                </div>
              </el-popover>
            </div>
          </div>
          <div class="laa">
            <div class="laa1">
              下载和关注
            </div>
            <el-popover placement="left" width="150" trigger="hover">
              <div slot="reference" class="refe">
                <img src="../image/ic.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活App</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            <el-popover placement="left" width="150" trigger="hover">
              <div slot="reference" class="refa">
                <img src="../image/ic.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活公众号</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            <!-- <el-popover
              placement="left"
              width="150"
              trigger="hover"
            >
              <div
                slot="reference" class="refa"
              >
                <img
                  src="../image/ic.png" class="refeimg"
                >
                <el-link
                  :underline="false"
                  type="info"
                >大众点评App</el-link>
              </div>
              <div>
                <img
                  src="../image/qr.jpg" class="imgqr"
                >
              </div>
            </el-popover> -->
          </div>
          <div class="ica">
            <div class="ica1">

            </div>
            <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refe">
                <img src="../image/ic.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活微博</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refa">
                <img src="../image/ic.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活微信公众号</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            <!-- <el-popover
              placement="right"
              width="150"
              trigger="hover"
            >
              <div
                slot="reference" class="refa"
              >
                <img
                  src="../image/ic.png" class="refeimg"
                >
                <el-link
                  :underline="false"
                  type="info"
                >众聚生活微信视频号</el-link>
              </div>
              <div>
                <img
                  src="../image/qr.jpg" class="imgqr"
                >
              </div>
            </el-popover> -->
          </div>
        </div>
      </div>
      <div style="width: 20%;">

      </div>
    </div>
    <div class="bota1">
      <div class="botla">
        众聚生活（北京酷演科技有限公司）公司住址：北京市海淀区万泉河路68号紫金大厦10层1011；公司联系电话：13051999938
      </div>
      <!-- <div style="display: flex;justify-content: center;align-items: center;font-size: 13px;color: #909399;">
        <div>
          ©2024 众聚生活版权所有
        </div>
        <el-link type="info">京ICP备10211739号</el-link>
        <el-link type="info">京公网安备 11000002002052号</el-link>
        <el-link type="info">隐私政策</el-link>
        <el-link type="info">规则中心</el-link>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Bottom",
  data() {
    return {

    }

  }
}
</script>
<style scoped>
.botpage {
  display: flex;
  background-color: #f0f2f3;
  padding: 20px 0px;
  border-bottom: 1px solid #dcdddf;
}

.botpage1 {
  width: 60%;
  display: flex;
}

.botpage2 {
  width: 40%;
  display: flex;
}

.img {
  border-radius: 10px;
  width: 40px;
  height: 40px;
}

.tit {
  margin-left: 10px;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-top: -3px;
}

.labelcenter {
  width: 60%;
  display: flex;
}

.labelcenter1 {
  width: 20%;
}

.labeltit {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
}

.label1 {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
  padding-top: 10px;
}

.label2 {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
}

.label3 {
  font-size: 15px;
  color: #000;
  /* margin-bottom: 10px; */
}

.laa {
  width: 25%;
  padding-left: 20px;
}

.laa1 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  display: flex;
}

.refe {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
  display: flex;
  padding-top: 10px;
}

.refeimg {
  border-radius: 5px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.imgqr {
  width: 150px;
  height: 150px;
}

.refa {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
  display: flex;
}

.ica {
  width: 25%;
  padding-left: 20px;
}

.ica1 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  height: 20px;
}

.bota1 {
  padding: 30px;
  background-color: #f3f4f6;
}

.botla {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #909399;
  margin-bottom: 10px;
}
</style>
