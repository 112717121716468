import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icon/iconfont.css'
import axios from 'axios'
import { Popup, Cell, CellGroup, Icon } from 'vant'
Vue.use(Popup).use(Cell).use(CellGroup).use(Icon)

Vue.use(ElementUI)

axios.defaults.baseURL='https://gwapi.kukuyan.cn/api/'
Vue.prototype.axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// const baseURL = process.env.VUE_APP_BASE_API

// export default {
//   zip(url, name) {
//     var url = baseURL + url
//     axios({
//       method: 'get',
//       url: url,
//       responseType: 'blob',
//       headers: { 'Authorization': 'Bearer ' + getToken() }
//     }).then(async (res) => {
//       const isLogin = await blobValidate(res.data);
//       if (isLogin) {
//         const blob = new Blob([res.data], { type: 'application/zip' })
//         this.saveAs(blob, name)
//       } else {
//         this.printErrMsg(res.data);
//       }
//     })
//   },
//   saveAs(text, name, opts) {
//     saveAs(text, name, opts);
//   },
//   async printErrMsg(data) {
//     const resText = await data.text();
//     const rspObj = JSON.parse(resText);
//     const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
//     Message.error(errMsg);
//   }
// }
